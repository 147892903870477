import { connect } from 'react-redux'

import IntegrationsSection from 'components/home-page/views/sections/integrations'

const mapStateToProps = state => {
  return {
    isGoogleTranslateEnabled:
      state.siteSettings.settings.googleTranslationEnabled
  }
}

export default connect(mapStateToProps)(IntegrationsSection)
