import React from 'react'
import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'
import isUserOnIE from 'lib/is-user-on-ie'

const GoogleTranslate = dynamic(() =>
  import(
    /* webpackChunkName: 'GoogleTranslate' */ 'containers/hub-page/views/sections/integrations/google-translate'
  )
)

const IntegrationsSection = ({ isGoogleTranslateEnabled, dataTestId }) => {
  return (
    <section className='ehq-integrations-section'>
      {isGoogleTranslateEnabled && !isUserOnIE() && <GoogleTranslate />}
      <style jsx>
        {`
          .ehq-integrations-section {
            width: 100%;
            height: 40px;
            color: #262626;
            display: flex;
            align-items: center;
            padding-left: 20px;
            padding-right: 20px;
            background-color: #f5f5f5;
            justify-content: space-between;
          }
        `}
      </style>
    </section>
  )
}

IntegrationsSection.propTypes = {
  isGoogleTranslateEnabled: PropTypes.bool.isRequired,
  dataTestId: PropTypes.string.isRequired
}

export default IntegrationsSection
