import { connect } from 'react-redux'

import IntegrationsSection from 'components/hub-page/views/sections/integrations'

const mapStateToProps = state => {
  return {
    isGoogleTranslateEnabled:
      state.siteSettings.settings.googleTranslationEnabled,
    dataTestId: 'participant-hubs-views-integration-section'
  }
}

export default connect(mapStateToProps)(IntegrationsSection)
